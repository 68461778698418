import { useContext } from "react";
import ReactPlayer from "react-player"
import MusicPlayerContext from "../../contexts/music-player-context";
import { roundToNearestQuarter } from "../../utils";
import mediaItemTypes from "../../enums/media-item-types";

const VideoPlayer = ({ src, mediaItem }) => {
  const {
    pause,
    isPlaying,
    current,
    currentNote,
    setTrackProgress,
    startNew,
    setCurrentNote,
  } = useContext(MusicPlayerContext);

  const handlePlayVideo = () => {
    startNew(mediaItem)
  };

  const handleTrackProgress = (p) => {
    // avoid resetting progress if music
    // already playing
    if (current?.id === mediaId && isPlaying) {
      setTrackProgress(p);
    };

    if (mediaItem.type === mediaItemTypes.PLAYALONG && mediaItem.is_transcribed) {
      const time = roundToNearestQuarter(p);
      const accomplishedTime = Number(Object.keys(mediaItem.transcription)
        .sort((a, b) => b - a)
        .find((e) => {
          return time >= Number(e)
        }));

      setCurrentNote(mediaItem.transcription[accomplishedTime]);
    };
  }

  // edit and new pages don't have ids
  // ID is needed to know when to stop playing
  const mediaId = mediaItem?.id ? mediaItem.id : Math.random() * 1000;

  return (
    <div style={{ borderRadius: 10, overflow: "hidden" }}>
      <ReactPlayer
        url={src}
        width="100%"
        height={225}
        playing={current?.id === mediaId && isPlaying}
        onPlay={handlePlayVideo}
        onPause={() => pause()}
        onProgress={(p) => handleTrackProgress(p.playedSeconds)}
        volume={1}
        controls
      />
    </div>
  )
}

export default VideoPlayer;