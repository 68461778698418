import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Space,
  notification,
  List,
  Button,
  Skeleton,
  Result,
  theme,
} from "antd";
import {
  ArrowRightOutlined,
  ReadOutlined,
  ShareAltOutlined,
  EditOutlined,
  CheckCircleOutlined,
  HeartFilled,
  HeartOutlined,
} from "@ant-design/icons";
import AuthContext from "../../contexts/auth-context";
import { getCourse, toggleCourseFavorite } from "../../services/courses";
import UserProfilePopover from "../../components/user-profile-popover/user-profile-popover";
import { getTimeAgo } from "../../utils";
import styles from "./course-detail.module.scss";

const SkeletonCourseDetail = () => {
  return (
    <>
      <Skeleton active paragraph={{ rows: 0 }} />
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card size="small">
            <Skeleton active paragraph={{ rows: 1 }} />
          </Card>
        </Col>
        <Col span={24}>
          <Card size="small">
            <Skeleton active paragraph={{ rows: 2 }} />
          </Card>
        </Col>
        <Col span={24}>
          <Card size="small">
            <Skeleton active paragraph={{ rows: 6 }} />
          </Card>
        </Col>
      </Row>
    </>
  )
}


const ActionButtons = ({ course }) => {
  const { isLoggedIn, token, tokenClaim } = useContext(AuthContext);
  const nagivate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(course.is_favorite);

  const handleEditClick = () => nagivate(`/courses/${course.id}/edit`);

  const handleFavoriteClick = async () => {
    try {
      await toggleCourseFavorite(token, course);

      setIsFavorite((fav) => !fav);

      notification.open({
        message: isFavorite ? "Course Unfavorited" : "Course Favorited",
        description: `This course has been ${isFavorite ? "removed" : "added"
          } to your favorites list.`,
        placement: "bottomRight",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShare = () => {
    let hostname = window.location.hostname;
    if (hostname === "localhost") hostname = "localhost:3000";
    navigator.clipboard.writeText(`${hostname}/courses/${course.id}`);

    notification.open({
      message: "Link copied to clipboard!",
      placement: "bottomRight",
    });
  };
  return (
    <Space>
      {tokenClaim.username === course.user.username && (
        <Button size="small" type="text" onClick={handleEditClick}>
          <EditOutlined />
        </Button>
      )}
      {isLoggedIn && (
        <Button size="small" type="text" onClick={handleFavoriteClick}>
          {isFavorite ? <HeartFilled /> : <HeartOutlined />}
        </Button>
      )}
      <Button size="small" type="text" onClick={handleClickShare}>
        <ShareAltOutlined />
      </Button>
    </Space>
  );
};


const CourseHeaderCard = ({ course }) => {
  return (
    <Card size="small">
      <Typography.Title level={3} style={{ marginBottom: 0 }}>
        {course.title}
      </Typography.Title>
      <Row gutter={[12, 12]} justify="space-between">
        <Col>
          <Space
            split={<Typography.Text type="secondary">•</Typography.Text>}
          >
            <div>
              <Typography.Text type="secondary"><small>Created by </small></Typography.Text>
              <UserProfilePopover
                user={course.user}
                isFollowing={course.in_following_feed}
              >
                <Typography.Link>
                  <small>
                    {course.user.username}
                  </small>
                </Typography.Link>
              </UserProfilePopover>
            </div>
          </Space>
        </Col>
        <Col>
          <ActionButtons course={course} />
        </Col>
      </Row>
    </Card>
  )
}

const CourseDescriptionCard = ({ description }) => {
  return (
    <Card size="small">
      <Typography.Title level={5}>
        Description
      </Typography.Title>
      <Typography.Text type="secondary">
        {description}
      </Typography.Text>
    </Card>
  )
}

const CourseProgressCard = ({ lessons }) => {
  const {
    token: { colorSuccess, colorPrimary },
  } = theme.useToken();

  const completedCourses = lessons.filter((l) => l.is_completed).length;
  const completePercentage = completedCourses / lessons.length;
  const hasProgress = completePercentage > 0;
  const lineColor = completePercentage === 1 ? colorSuccess : colorPrimary;
  const completePercString = `${Math.round(100 * completePercentage)}%`

  return (
    <Card size="small">
      <Row justify="space-between">
        <Col>
          <Typography.Title level={5} style={{ marginBottom: hasProgress ? "" : 0 }}>
            Course Progress
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Text type="secondary">
            {completePercString}
          </Typography.Text>
        </Col>
      </Row>
      {hasProgress && (
        <div>
          <hr style={{ border: `2px solid ${lineColor}`, borderRadius: 5, width: completePercString }} />
        </div>
      )}
    </Card>
  )
}

const CourseLessonsCard = ({ course }) => {
  const { tokenClaim } = useContext(AuthContext);
  const navigate = useNavigate();
  const getActionButton = (lesson) => {
    if (!lesson.is_published) {
      return ([
        <Typography.Text type="secondary">
          DRAFT
        </Typography.Text>
      ])
    }
    if (lesson.is_completed) {
      return [
        <Button type="text" disabled>
          <Typography.Text type="success">
            <Space>
              Completed
              <CheckCircleOutlined />
            </Space>
          </Typography.Text>
        </Button>
      ]
    }

    return [<Button type="link">
      <Space onClick={() => navigate(`/courses/${course.id}/lessons/${lesson.id}`)}>
        Start Lesson
        <ArrowRightOutlined />
      </Space>
    </Button>]
  }
  return (
    <Card size="small">
      <Typography.Title level={5}>
        Lessons
      </Typography.Title>
      {course.lessons.length === 0 && (
        <Result
          title="Nothing here yet."
          subTitle={tokenClaim.username === course.user.username ? "Create some lessons for this course" : "This course doesn't have any lessons yet. Please check back later!"}
        // icon={<UsergroupAddOutlined />}
        />
      )}
      <List size="large" dataSource={course.lessons}>
        {course.lessons.sort((a, b) => a.order - b.order).map((l) => (
          <List.Item
            key={l.id}
          // actions={getActionButton(l)}
          >
            <Row justify="space-between" align="middle" gutter={[12, 12]} style={{ width: "100%" }}>
              <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                <div>
                  <Space>
                    <Typography.Text
                      onClick={() => navigate(`/courses/${course.id}/lessons/${l.id}`)}
                      className={styles["lesson-title"]}
                    >
                      <b>{l.title}</b>
                    </Typography.Text>
                  </Space>
                  <div>
                    <Typography.Text type="secondary">
                      {l.description}
                    </Typography.Text>
                  </div>
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 8 }} style={{ textAlign: "end" }}>
                {getActionButton(l)}
              </Col>
            </Row>
          </List.Item>
        ))}
      </List>
    </Card>
  )
}

const CourseDetailPage = ({ }) => {
  const { courseId } = useParams();
  const { token, isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [course, setCourse] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const breadcrumbs = [
    {
      href: null,
      onClick: () => navigate("/courses/recent"),
      title:
        <Space><ReadOutlined /> Courses</Space>,
    },
    {
      title: course?.title || "",
    },
  ]

  const loadData = async () => {
    setIsLoading(true);
    try {
      const courseData = await getCourse(token, courseId);
      setCourse(courseData);
    } catch {
      notification.open({
        message: "We didn't find what your looking for...",
        description: "We are redirecting you to the home page.",
        placement: "bottomRight",
      });
      navigate("/");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData({});
  }, [location.pathname]);

  return (
    <div>
      {isLoading && <SkeletonCourseDetail />}
      {!isLoading && (
        <>
          <Row>
            <Col style={{ margin: "0px 8px 8px 8px" }}>
              <Breadcrumb items={breadcrumbs} />
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <CourseHeaderCard course={course} />
            </Col>
            <Col span={24}>
              <CourseDescriptionCard description={course.description} />
            </Col>
            {isLoggedIn && course.is_published && course.lessons.length > 0 && (
              <Col span={24}>
                <CourseProgressCard lessons={course.lessons} />
              </Col>
            )}
            <Col span={24}>
              <CourseLessonsCard course={course} />
            </Col>
            <br />
          </Row>
        </>
      )}
    </div>
  )
}

export default CourseDetailPage;